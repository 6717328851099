import Field from '../types/Field'

import Model from './Model'

export default class ProductStatus extends Model {
    endpoint = 'product_statuses';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name_es', 'Nombre (español)'),
      new Field('name_en', 'Nombre (inglés)'),
      new Field('order', 'Orden'),
      // new Field('background_color', 'Color de borde', 'color').applyMask(value => `<span style="width:30px;height:30px; background:${value};border:1px solid black;display:block;border-radius:100%;"></span>`),
      // new Field('text_color', 'Color de texto', 'color').applyMask(value => `<span style="width:30px;height:30px; background:${value};border:1px solid black;display:block;border-radius:100%;"></span>`),
    ]

    clone = () => ProductStatus;
}
